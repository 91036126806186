import React, { useEffect } from 'react';
import '../styles/index.css';
import useInnerVh from '../hooks/useInnerVh';
import Head from '../components/Head/Head';
import Header from '../components/Header/Header';
import FreeFooter from '../components/common/Footer/FreeFooter/FreeFooter';
import classConcat from '../util/ClassConcat';
import ContactUs from '../components/contactUs';

const ThankYou = ({ location }) => {
  useInnerVh();
  useEffect(() => {
  }, []);
  // Render
  return (
    <>
      <Head />
      <Header pageId={location.pathname} />
      <section className="full-section">
        <div
          className={classConcat('container')}
          style={{ marginTop: '120px' }}
        >
          <h2 className="section__h1">Contact Us</h2>
          <center>
            <ContactUs page="contact-us" />
          </center>
        </div>
      </section>
      <FreeFooter />
    </>
  );
};

export default ThankYou;
